@use "../../partials/colors" as*;
@use "../../partials/breakpoints" as*;


@-webkit-keyframes slide-left {
    0% {
        width: 0;
        overflow: hidden;
       
    }

    100% {
        width: 14rem;
        overflow: hidden;
    }
}

@keyframes slide-left {
    0% {
        width: 0;
        overflow: hidden;
    }

    100% {
        overflow: hidden;
        width: 14rem;
    }
}

@-webkit-keyframes slide-right {
    0% {
        width: 14rem;
        display: block;
        opacity: 1;
    }
    70% {
        opacity: 0;
    }

    100% {
        width: 0;
        opacity: 0;
        overflow: hidden;
        display: none;
    }
}

@keyframes slide-right {
    0% {
        width: 14rem;
        display: block;
    }
    70% {
        opacity: 0;
    }

    100% {
        width: 0px;
        opacity: 0;
        overflow: hidden;
        display: none;
    }
}

.mobile-nav {
    display: block;
    position: relative;

    @include desktop {
        display: none;
    }

    &__button {
        background-color: transparent;
        border: none;
    }

    &__icon {
        width: 1.5rem;

        @include tablet {
            width: 2rem;
        }

        @include tiny-mobile {
            width: 1.25rem;
        }
    }

    &__box {
        position: absolute;
        z-index: 1;
        top: 2.1rem;
        right: 0;
        min-height: 20rem;

        @include tablet {
            top: 3.4rem;
        }

        @include tiny-mobile {
            top: 2.1rem;
        }

        &--out {
            -webkit-animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            background-color: $beige;
            border: 2px solid $wine-red;
            border-top: 1rem solid $wine-red;
            width: 14rem;
            padding: 1rem;
            height: 23.25rem;
        }
        &--none {
            animation: none;
            display: none;
        }
    }

    &__list {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        background-color: $beige;
        border: 2px solid $wine-red;
        border-top: 1rem solid $wine-red;
        width: 14rem;
        padding: 1rem;
        max-height: 80vh;
        overflow: scroll;
        white-space: nowrap;
        -webkit-animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    &__item {
        padding: 0;
        display: flex;
        align-items: center;

        & .active {
            border-bottom: 1px solid $wine-red;
            scale: 1.1;
            background-color: $golden;
            width: 100%;
        }
    }

    &__link {
        padding: .3rem .5rem;
        text-decoration: none;
        font-size: .9rem;
        color: $wine-red;
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
        cursor: pointer;

        &:hover {
            border-bottom: 1px solid $wine-red;
            scale: 1.1;
        }

    }

    &__animate-out {
        -webkit-animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
}