@use "../../../partials/colors" as*;
@use "../../../partials/breakpoints" as*;

.page-title {
    font-size: .9rem;
    text-transform: uppercase;
    color: $beige;
    text-align: center;
    margin: 0;
    width: 100%;
    background-color: $wine-red;
    padding-top: 2px;

    @include tablet {
        font-size: 1rem;
        text-transform: uppercase;
        letter-spacing: .1rem;
    }

    @include tiny-mobile {
        font-size: .8rem;
    }
}