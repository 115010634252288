@use "../../partials/colors" as*;
@use "../../partials/breakpoints"as*;

.obituary {
    background-color: white;
    width: 100%;
    max-width: 50rem;
    padding: 1rem;
    @include tablet {
        padding: 1.5rem;
    }
    @include desktop {
        padding: 2rem;
    }
    &__border {
        border: 1rem solid;
        border-image-source: url('../../assets/obituary-frame.svg');
        border-image-slice: 30;
        display: flex;
        flex-direction: column;
        @include desktop {
            border-image-width: 2rem;
            padding: 2rem;
            border-image-slice: 20;
        }
    }
    &__top {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 1rem;
        
        padding: 1rem;
        @include desktop {
            
        }
    }
    &__info {
        text-align: center;
        max-width: 100%;
        min-width: calc(100% - 11rem);
    }
    &__cross {
        width: 4rem;
        @include desktop {
            width: 6rem;
        }
    }
    &__image{
        max-width: 10rem;
    }
    &__content{
        width: 100%;
        text-align: center;
        padding-top: 1rem;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 6;
        @include desktop {
            padding-top: 3rem;
        }
    }
    &__continue {
        text-decoration: none;
        padding-top: 1rem;
        color: $wine-red;
        text-align: end;
        align-self: flex-end;
        cursor: pointer;
        &::after {
            content: " >>";
        }
    }
}