@use "../../partials/colors" as*;
@use "../../partials/breakpoints" as*;

.main-nav {
    display: none;

    @include desktop {
        display: block;
    }

    &__list {
        list-style: none;
        display: flex;
        justify-content: flex-end;
        gap: .5rem;
    }

    &__item {
        padding: 0;
        display: flex;
        align-items: center;

        & .active {
            border-bottom: 1px solid $wine-red;
            scale: 1.1;
        }
    }

    &__link {
        padding: .3rem .5rem;
        text-decoration: none;
        font-size: .85rem;
        color: $wine-red;
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
        cursor: pointer;

        &:hover {
            border-bottom: 1px solid $wine-red;
            scale: 1.1;
        }
    }
}