@use "../../partials/colors" as*;
@use "../../partials/breakpoints" as*;
@use "../../partials/global" as*;

.home {
    width: 100%;
    margin: 0 auto;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @include desktop {
        max-width: 1280px;
        padding: 2rem;
    }

    &__top {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        @include tablet {
            display: grid;
            grid-template-columns: 2fr 1fr;
            grid-gap: 2rem;
        }
    }
}