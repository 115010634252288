@use "../../../partials/colors" as*;
@use "../../../partials/breakpoints" as*;

.worship-offices-list {
    max-width: 70rem;
    margin: 0 auto;
    padding: 1rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas:
        "featured";
    gap: 1rem;

    @include tablet {
        padding: 1.5rem;
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas:
            "featured featured";
        gap: 1.5rem;
    }

    @include desktop {
        padding: 2rem;
        gap: 2rem;
        grid-template-columns: repeat(4, 1fr);
        grid-template-areas:
            "featured featured featured featured";
    }

    >.worship-office-item:first-child {
        grid-area: featured;
    }

}