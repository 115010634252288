@use "../../../partials/colors" as*;
@use "../../../partials/breakpoints" as*;

.announcement {
    margin: 1rem auto;
    padding: 1.5rem;
    background-color: $golden;
    color: $wine-red;
    font-style: italic;
    text-align: center;
    display: flex;
    flex-direction: column;
    min-height: 300px;
    align-items: center;
    justify-content: center;
    border: 4px solid $wine-red;
}