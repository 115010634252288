@use "../../../partials/colors" as*;
@use "../../../partials/breakpoints" as*;

.worship-office-latest {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    color: $wine-red;
    border: 1px solid $wine-red;
    width: 100%;
    max-width: 50rem;
    margin: 0 auto;

    &__text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: .5rem;

    }

    &__title {
        background-color: $golden;
        padding: .5rem 1rem;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        font-size: 1.1rem;

        @include tablet {
            font-size: 1.2rem;
        }

        @include desktop {
            font-size: 1.5rem;
        }
    }

    &__date {
        background-color: $wine-red;
        color: $beige;
        padding: 0 1rem;
        text-align: end;
        font-size: 1.5rem;
    }

    &__image {
        aspect-ratio: 16/9;
        overflow: hidden;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $wine-red;

        &:hover {
            opacity: .8;
        }

    }

    &__icon {
        position: absolute;
        width: 3rem;

        @include desktop {
            width: 4rem;
        }

        &:hover {
            scale: 1.1;
        }

        &:active {
            scale: .9;
        }
    }
}