@use "../../../partials/colors" as*;

.modal {
    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__container {
        background-color: $beige;
        padding: 0;
        border-radius: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        max-width: 50rem;
        max-height: 70vh;
        width: fit-content;
        position: relative;
        overflow: auto;
    }

    &__close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        cursor: pointer;
        border: none;
        padding: .25rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;

        &:hover {
            scale: 1.1;
        }
    }

    &__x {
        fill: $wine-red;
        height: 1.5rem;
        width: 1.5rem;
    }
}