@use "./partials/colors"as*;
@use "./partials/global"as*;

*, *::after, *::before {
  box-sizing: border-box;
  margin: 0;
}

body {
  font-family: 'El Messiri', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $beige;
}

input, textarea, span, button {
  font-family: 'El Messiri', sans-serif;
}

button {
  cursor: pointer;
}
