@use "../../../partials/colors" as*;
@use "../../../partials/breakpoints" as*;
@use "../../../partials/global" as*;

.parish-priest-blurb {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    color: $wine-red;
    border: 4px solid $wine-red;

    &__image-box {
        flex-basis: 30%;
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__text-background {
        flex-basis: 70%;
        padding-top: 9.375rem;
        background-image: url("../../../assets/iconostas.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &__text-box {
        padding: 1rem 1rem .5rem 1rem;
        background-color: $beige;
        display: flex;
        flex-direction: column;
        gap: .75rem;
        text-align: center;

        &>h2 {
            font-size: 1.5rem;
            line-height: 1;
            padding: 0;
            margin: 0;
        }

        &>p {
            padding: 0;
            margin: 0;
            line-height: 1.2;
        }
    }

    &__link {
        text-decoration: none;
        color: inherit;
        text-align: right;

        &:hover {
            text-decoration: underline;
        }

        &::after {
            content: "  >>";
        }
    }
}