@use "../../partials/colors" as*;
@use "../../partials/breakpoints" as*;
.contact {
    min-height: 60vh;
    &__title {
        text-align: center;
        max-width: 50rem;
        margin: 1rem auto;
        color: $wine-red;
    }
    &__form {
        padding: 1rem;
        max-width: 50rem;
        margin: 1rem auto 2rem auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 1rem;
    }
    &__field {
        display: flex;
        flex-direction: column;
        gap: .25rem;
        width: 100%;
    }
    &__label {
        color: $wine-red;
        font-size: 1.1rem;
    }
    &__message {
        resize: none;
        padding: .5rem;
        min-height: 6rem;
        border: 1px solid $wine-red;
        &:focus {
            outline: 2px solid $wine-red;
        }
    }
    &__input {
        font-size: 1rem;
        padding: .5rem;
        border: 1px solid $wine-red;
        &:focus {
            outline: 2px solid $wine-red;
        }
    }
}