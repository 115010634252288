@use "../../partials/colors" as*;
@use "../../partials/breakpoints" as*;

.priest-bio {
    display: flex;
    flex-direction: column-reverse;
    padding: 2rem;
    color: $wine-red;
    max-width: 80rem;
    margin: 0 auto;

    @include desktop {
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-gap: 2rem;
    }

    &__image {
        height: 100%;
        width: 100%;
        max-width: 400px;
        max-height: 500px;
    }

    &__image-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include tablet {
            display: flex;
            flex-direction: row;
            gap: 1rem;
            max-width: 50rem;
            margin: 0 auto;
            align-items: flex-start;
        }

        @include desktop {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    &__bio {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        background-color: $golden;
        padding: 1rem 2rem;
        max-width: 50rem;
        margin: 0 auto 2rem auto;

        @include desktop {
            margin: 0 auto;
        }

    }

    &__documents {
        display: flex;
        flex-direction: column;
        gap: .5rem;
    }

}