@use "./colors" as*;

.image {
    width: 100%;
}

.button {
    text-decoration: none;
    text-align: center;
    margin: 1rem auto;
    padding: .25rem 2rem;
    background-color: $wine-red;
    color: $beige;
    border-radius: 4px;
    //border: 4px solid $wine-red;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;

    &:hover {
        color: $wine-red;
        background-color: $golden;
    }
}