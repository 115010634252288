@use "../../../partials/colors" as*;

.next-event {
    &__box {
        max-width: 50rem;
        margin: 1rem auto;
    }

    &__error {
        color: $wine-red;
        font-size: 1.2rem;
        margin: 2rem auto;
        text-align: center;
    }

    &__main {
        // margin: 2rem auto;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}