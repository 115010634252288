@use "../../../partials/colors" as*;

.calendar-carousel {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 1.25rem auto;
    width: 90%;
    max-width: 600px;

    &__arrow-left {
        width: 2rem;
        rotate: 180deg;
        fill: $beige;
        transition: fill 0.2s;

        &:hover {
            fill: $golden;
        }
    }

    &__arrow-right {
        width: 2rem;
        fill: $beige;
        transition: fill 0.2s;

        &:hover {
            fill: $golden;
        }
    }

    &__button {
        background-color: transparent;
        border: none;
        font-size: 1rem;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background-color: $wine-red;
        padding: 0.5rem 1rem;
    }

    &__month {
        font-size: 1.5rem;
        color: $beige;
        padding: 0;
        margin: 0;
    }

    &__content {
        background-color: $golden;
        padding: 0.5rem 1.25rem;
        width: 100%;
        // max-height: 600px;
        //overflow-y: auto; // Enable vertical scrolling for content

        // Custom Scrollbar Styling
        // &::-webkit-scrollbar {
        //     width: 8px;
        // }

        // &::-webkit-scrollbar-track {
        //     background: $golden;
        //     border-radius: 5px;
        // }

        // &::-webkit-scrollbar-thumb {
        //     background: $wine-red;
        //     border-radius: 5px;
        // }
    }

    &__list {
        display: flex;
        flex-direction: column;
    }
}