@use "../../../partials/colors" as*;
@use "../../../partials/breakpoints" as*;

.feast-item {
    border: 4px solid $wine-red;
    border-left: none;
    border-right: none;
    background-color: $beige;
    width: 100%;
    background-color: $golden;

    // @include desktop {
    //     // border-left: 1px solid $wine-red;
    //     // border-right: 1px solid $wine-red;
    //     max-width: 50rem;
    //     margin: 1rem auto;
    // }

    &__header {
        background-color: $wine-red;
        color: $beige;
        display: flex;
        justify-content: space-between;
        padding: .25rem 1rem;
    }

    &__title {
        font-size: 1.2rem;
        line-height: 1.2;
    }

    &__date {
        font-size: 1.25rem;
        line-height: 1;
    }

    &__main {
        padding: .5rem 1rem;
        text-align: center;
        color: $wine-red;
    }
}