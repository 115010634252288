@use "../../../partials/colors" as*;

.book-modal {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 0 1rem;
    background: transparent;
    border: none;
    color: $golden;

    &:hover {
        scale: 1.1;
        cursor: pointer;
    }

    &:active {
        scale: .9;
    }

    &__title {
        font-size: 1.1rem;
    }

    &__main {
        min-width: 18.75rem;
        max-width: 50rem;
        padding: 2rem;
        background-image: url("../../../assets/old-paper.jpg");
        background-size: cover;
        background-position: center;
    }
}