@use "../../partials/colors" as*;
@use "../../partials/breakpoints"as*;

.community-news-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    margin-bottom: 1rem;
    @include tablet {
        gap: 1.5rem;
        padding: 2rem;
        margin-bottom: 1.5rem;

    }
    @include desktop {
        margin-top: 2rem;
        gap: 2rem;
        padding: 0;
        margin-bottom: 2rem;
    }
}